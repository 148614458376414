import heading from './assets/fonts/BebasNeue-Regular.ttf';
import paragraph from './assets/fonts/D-DINExp.ttf';

import { Global, MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useColorScheme } from '@mantine/hooks';
import CardPage from './pages/cards';
import DeckPage from './pages/deck';

function App() {
  const colorScheme = useColorScheme();

  return (
    <>
      <Global
        // Note the format woff2; this is for some reason necessary
        styles={[
          {
            '@font-face': {
              fontFamily: 'Paragraph',
              src: `url('${paragraph}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Heading',
              src: `url('${heading}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
        ]}
      />

      <BrowserRouter>
        {/* STYLES */}
        <MantineProvider theme={{
          fontFamily: 'Paragraph, sans-serif',
          headings: { fontFamily: `Heading, sans-serif`, fontWeight: 800 },
          colorScheme: colorScheme === 'dark' ? 'dark' : 'light',
          white: colorScheme === 'dark' ? '#25262B' : '#F8F9FA',
          black: colorScheme === 'dark' ? '#F8F9FA' : '#25262B',
          primaryColor: 'cyan',
          primaryShade: 5
        }} withGlobalStyles withNormalizeCSS>
          <Routes>
            <Route path='/' element={ <DeckPage /> } />
            <Route path='/card' element={ <CardPage /> } />
          </Routes>
        </MantineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
