import cardData from '../data/cards.json';

import { Card, Center, SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { useViewportSize } from '@mantine/hooks';
import { useEffect, useState } from 'react';

export default function DeckPage() {

  /** Hooks */
  const { width } = useViewportSize();

  const [ collectedCards, setCollectedCards ] = useState([]);

  useEffect(() => { 
    /** Calculate collected cards from localstorage */
    setCollectedCards(localStorage.getItem('collectedCards') ? 
      JSON.parse(localStorage.getItem('collectedCards')) : []
    );
  }, []);

  return (
    <Center 
      style={{ 
        width: '100%', 
        height: '100%',
        padding: 20,
      }}
    >
      <Stack>
        <Title
          order={ 1 }
        >
          Your cards
        </Title>

        <Text>
          { collectedCards.length } / { cardData.length } collected!
        </Text>

        <SimpleGrid 
          cols={ width > 1000 ? 2 : 1 } 
          style={{ maxWidth: 800 }}
        >
          { cardData.map((el) => {
            console.log(collectedCards);
            let foundThis = collectedCards.find((e) => e === el.id) ? true : false;

            return (
              <Card
                shadow='sm'
                p='lg'
                radius='md'
                withBorder
                sx={(theme) => ({
                  background: foundThis ? 
                    'linear-gradient(45deg, rgba(229,74,69,1) 0%, rgba(166,114,248,1) 100%)' 
                    : null,
                  color: foundThis ? theme.white : null,
                })}
                key={ el.id }
              >
                <Stack style={{ gap: 5 }}>
                  <Text weight={ 800 } size='xl' transform='uppercase'>
                    { foundThis ? el.name : '?'.repeat(el.name.length) }
                  </Text>
                  <Text italic size='sm'>
                    { foundThis ? el.subtitle : '?'.repeat(el.subtitle.length) }
                  </Text>
                  <Text>
                    { foundThis ? el.description : null }
                  </Text>
                </Stack>
              </Card>
            )
          }
          )}
        </SimpleGrid>
      </Stack>
    </Center>
  )
}