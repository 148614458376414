import cardData from '../data/cards.json';

import { Button, Space, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CenterPage from "../components/layout/centerPage";
import { useDocumentTitle } from '@mantine/hooks';
import { Link } from 'react-router-dom';

export default function CardPage() { 

  /** Hooks */
  const pageUrl = useLocation().search;
  const [ docTitle, setDocTitle ] = useState('Unknown card • Grilldex');
  useDocumentTitle(docTitle);

  /** States */
  const [ name, setName ] = useState('');
  const [ subtitle, setSubtitle ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ isShiny, setIsShiny ] = useState(false);

  /** Init page */
  async function getCard() { 
    /** Get drop code from url */
    const cardID = new URLSearchParams(pageUrl).get('id');
    if (!cardID) { 
      /** Error*/
      setName('No card found!');
      setDescription('Tap or scan a card get started');
      return;
    }
    setIsShiny(new URLSearchParams(pageUrl).get('shiny') === 'true');

    let card = cardData.find(c => c.id === cardID);
    if (!card) { 
      /** Error */
      console.log('no card!');
      setName('No card found!');
      setDescription('Tap or scan a card to get started');
      return;
    }

    /** Update page */
    setName(card.name);
    setDocTitle(`${ card.name } • Grilldex`);
    setSubtitle(card.subtitle);
    setDescription(card.description);

    /** Update card collection */
    let collectedCards = localStorage.getItem('collectedCards') ? 
      JSON.parse(localStorage.getItem('collectedCards')) : []

    if (!collectedCards.find((e) => e === cardID)) { 
      collectedCards.push(cardID);
      localStorage.setItem('collectedCards', JSON.stringify(collectedCards));
    }
  }

  useEffect(() => { 
    getCard();
  }, []);

  return (
    <>
      <CenterPage>
        <Title 
          order={ 1 } 
          align='center'
          transform='uppercase'
        >
          { name } { isShiny ? '(Shiny!)' : null }
        </Title>

        <Text
          align='center'
          italic
          transform='uppercase'
        >
          { subtitle }
        </Text>

        <Text
          align='center'
          transform='uppercase'
        >
          { description }
        </Text>

        <Space h={ 20 } />

        <Button 
          color='orange' 
          radius='md'
          component={ Link }
          to='/'
        >
          Return home
        </Button>
      </CenterPage>
    </>
  )
}